import React from "react";

const thumbsUp = ({fill, style, width, viewBox, className}) => (
  <svg
  width={width}
    style={style}
    height={width}
    viewBox={viewBox}
  version="1.1"
  id="svg70"
  className={`svg-icon ${className || ""}`}
  >
 <defs
    id="defs64" />

 <metadata
    id="metadata67">
   <rdfRDF>
     <ccWork>
       <dcFormat>image/svg+xml</dcFormat>
      
       <dcTitle></dcTitle>
     </ccWork>
   </rdfRDF>
 </metadata>
 <g
    
    id="layer1"
    transform="translate(0,-6.8332109e-4)">
   <path
      id="path72"
      d="M 10.74,0.04 A 2.013,2.013 0 0 0 9.16,1.92 C 9.05,4.715 8.675,6.37 6.877,8.866 A 1.272,1.272 0 0 0 5.812,8.286 H 1.262 C 0.573,8.287 0,8.84 0,9.507 v 8.773 c 0,0.667 0.572,1.218 1.263,1.218 h 4.55 c 0.435,0 0.821,-0.22 1.049,-0.548 0.263,0.204 0.506,0.387 0.758,0.533 0.417,0.24 0.887,0.384 1.532,0.45 1.29,0.128 3.403,0.032 8.283,0.052 a 0.53,0.53 0 0 0 0.317,-0.113 C 18.976,19.205 22.007,14.097 22,9.338 21.974,8.2 21.458,7.558 20.468,7.558 H 13.96 C 14.348,5.088 14.091,2.82 13.225,1.35 12.76,0.555 12.078,0.111 11.403,0.018 A 2.035,2.035 0 0 0 10.74,0.04 m 2.154,7.912 c -0.055,0.28 0.201,0.58 0.498,0.58 h 6.934 c 0.356,0.035 0.67,0.091 0.67,0.913 0,1.047 -0.168,2.886 -1.031,5.057 -0.865,2.172 -2.155,4.531 -2.603,4.455 -1.215,0.08 -7.014,0.109 -8.108,0 C 8.698,18.901 8.436,18.822 8.141,18.651 7.875,18.499 7.551,18.228 7.075,17.86 v -7.6 c 2.349,-2.88 2.979,-5.302 3.096,-8.3 0.338,-1.495 1.702,-1.082 2.179,-0.13 0.697,2.402 0.879,4.442 0.544,6.122 M 1.263,9.262 h 4.55 c 0.148,0 0.251,0.1 0.251,0.244 v 8.773 c 0,0.144 -0.103,0.243 -0.252,0.243 h -4.55 c -0.148,0 -0.251,-0.099 -0.251,-0.243 V 9.506 c 0,-0.144 0.103,-0.244 0.252,-0.244" />
   <path 
   //    style={{fill:"#00ff00",strokeWidth:"0.217892"}}
      style={{fill: fill, strokeWidth:"0.217892"}}
      className="ziploker"
      d="m 35.115184,71.535355 c -2.9063,-0.300852 -4.148628,-0.865657 -7.135973,-3.244266 L 26.835276,67.380256 V 53.151395 38.922536 l 1.233704,-1.617739 c 6.509242,-8.535479 9.496844,-16.410753 10.321964,-27.208552 0.255707,-3.3462578 0.448341,-4.0634717 1.391964,-5.1825423 1.268186,-1.5039808 3.403337,-1.4173038 5.255873,0.2133635 1.270271,1.1181377 1.481309,1.5540921 2.257123,4.6626921 2.025352,8.1153607 2.33979,12.5836637 1.41139,20.0565297 -0.131476,1.058281 -0.09737,1.175026 0.525218,1.797612 l 0.665827,0.665826 h 13.602516 c 9.393386,0 13.816899,0.07474 14.295329,0.241517 1.119392,0.390221 1.381183,1.17959 1.357886,4.094392 -0.03104,3.883933 -0.705298,8.173237 -2.021847,12.862088 -1.921011,6.841616 -7.807261,18.806118 -10.513013,21.368944 L 65.814057,71.63929 51.281716,71.67191 C 43.28893,71.689845 36.01399,71.628398 35.115184,71.535355 Z"
      id="path118"
      transform="scale(0.26458333)" />
   <path 
      className="ziploker"
      style={{fill: fill, strokeWidth:"0.217892"}}
      d="M 4.44684,69.780416 3.9565823,69.583356 V 52.624312 c 0,-12.899844 0.062584,-17.021628 0.2614708,-17.220515 0.1949176,-0.194917 2.5500575,-0.26147 9.2527569,-0.26147 8.666419,0 8.999437,0.01523 9.216845,0.42146 0.149813,0.279927 0.225559,5.987311 0.225559,16.995601 0,16.283618 -0.0076,16.58178 -0.435785,17.009925 -0.421473,0.421473 -0.716646,0.435331 -8.988058,0.421972 -4.7037501,-0.0076 -8.7728892,-0.102487 -9.042531,-0.210869 z"
      id="path120"
      transform="scale(0.26458333)" />
 </g>
</svg>
);

export default thumbsUp;
