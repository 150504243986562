import React from "react";

const thumbsDown = ({fill, style, width, viewBox, className}) => (
  <svg
  width={width}
    style={style}
    height={width}
    viewBox={viewBox}
  version="1.1"
  id="svg71"
  className={`svg-icon ${className || ""}`}
  >
 <defs
    id="defs65" />

 <metadata
    id="metadata68">
   <rdfRDF>
     <ccWork>
       <dcFormat>image/svg+xml</dcFormat>
      
       <dcTitle></dcTitle>
     </ccWork>
   </rdfRDF>
 </metadata>
 <g
     
     id="layer11"
     transform="translate(0,-6.8332109e-4)">
    <path
       id="path722"
       d="m 11.260012,19.961613 a 2.013,2.013 0 0 0 1.58,-1.88 c 0.11,-2.795 0.485,-4.45 2.283,-6.946 a 1.272,1.272 0 0 0 1.065,0.58 h 4.55 c 0.689,-10e-4 1.262,-0.554 1.262,-1.221 V 1.7216128 c 0,-0.667 -0.572,-1.21800005 -1.263,-1.21800005 h -4.55 c -0.435,0 -0.821,0.22 -1.049,0.54800005 -0.263,-0.20400005 -0.506,-0.38700005 -0.758,-0.53300005 -0.417,-0.24 -0.887,-0.384 -1.532,-0.45 -1.29,-0.128 -3.4029999,-0.032 -8.2829999,-0.052 a 0.53,0.53 0 0 0 -0.317,0.113 c -1.224,0.667 -4.255,5.77500005 -4.247999995807,10.53400025 0.02599999581,1.138 0.541999995807,1.78 1.531999995807,1.78 h 6.508 c -0.388,2.47 -0.131,4.738 0.735,6.208 0.465,0.795 1.147,1.239 1.8219999,1.332 a 2.035,2.035 0 0 0 0.663,-0.022 m -2.1539999,-7.912 c 0.055,-0.28 -0.201,-0.58 -0.498,-0.58 h -6.934 c -0.356,-0.035 -0.67,-0.091 -0.67,-0.913 0,-1.0470002 0.168,-2.8860002 1.031,-5.0570002 0.865,-2.172 2.155,-4.53100005 2.603,-4.455 1.215,-0.08 7.0139999,-0.10900005 8.1079999,0 0.556,0.056 0.818,0.135 1.113,0.306 0.266,0.152 0.59,0.423 1.066,0.791 v 7.6 c -2.349,2.8800002 -2.979,5.3020002 -3.096,8.3000002 -0.338,1.495 -1.702,1.082 -2.1789999,0.13 -0.697,-2.402 -0.879,-4.442 -0.544,-6.122 m 11.6309999,-1.31 h -4.55 c -0.148,0 -0.251,-0.1 -0.251,-0.244 V 1.7226128 c 0,-0.144 0.103,-0.243 0.252,-0.243 h 4.55 c 0.148,0 0.251,0.099 0.251,0.243 v 8.7730002 c 0,0.144 -0.103,0.244 -0.252,0.244" />
    <path
    style={{fill: fill, strokeWidth:"0.217892"}}
       className="ziploker"
       //style="fill:#00ff00;stroke-width:0.05765059"
       d="m 12.70912,1.0745508 c 0.768958,0.0796 1.097658,0.229038 1.888059,0.858378 l 0.302666,0.240991 v 3.76472 3.764719 l -0.326417,0.4280262 c -1.722237,2.258346 -2.512707,4.342012 -2.73102,7.19893 -0.06766,0.885364 -0.118623,1.075127 -0.36829,1.371214 -0.335541,0.397928 -0.900466,0.374995 -1.390617,-0.05645 C 9.7474089,18.349236 9.6915718,18.23389 9.4863043,17.411406 8.95043,15.264217 8.8672349,14.081978 9.1128741,12.104782 9.1476604,11.824779 9.1386365,11.79389 8.9739101,11.629164 L 8.7977434,11.452998 h -3.598999 c -2.4853333,0 -3.6557211,-0.01977 -3.7823057,-0.0639 -0.2961725,-0.103246 -0.365438,-0.3121 -0.359274,-1.083308 0.00821,-1.0276242 0.1866101,-2.1625022 0.534947,-3.4030942 0.5082675,-1.810178 2.0656711,-4.975786 2.781568,-5.653867 l 0.21303,-0.201777 3.8450152,-0.0086 c 2.1147581,-0.0047 4.0395861,0.01151 4.2773951,0.03613 z"
       id="path1188"
       />
    <path
    style={{fill: fill, strokeWidth:"0.217892"}}
      className="ziploker"
      /// style="fill:#00ff00;stroke-width:0.05765059"
       d="m 20.823452,1.5388778 0.129714,0.05214 v 4.48708 c 0,3.413084 -0.01656,4.5036392 -0.06918,4.5562612 -0.05157,0.05157 -0.674703,0.06918 -2.448126,0.06918 -2.29299,0 -2.381101,-0.004 -2.438623,-0.111511 -0.03964,-0.07406 -0.05968,-1.5841432 -0.05968,-4.4967532 0,-4.308374 0.002,-4.387262 0.115301,-4.500542 0.111515,-0.111515 0.189613,-0.115182 2.37809,-0.111647 1.244534,0.002 2.321161,0.02712 2.392503,0.05579 z"
       id="path1200"
        />
  </g>
</svg>
);

export default thumbsDown;






// <svg viewBox="0 0 22 20" xmlns="http://www.w3.org/2000/svg">


//<path key={item.id + "path2"} data-id={ item.id + "path2"} d="M11.26 19.96a2.013 2.013 0 001.58-1.881c.11-2.794.484-4.45 2.282-6.945.224.345.618.58 1.066.58h4.548c.692 0 1.264-.553 1.264-1.22V1.722c0-.668-.572-1.22-1.264-1.22h-4.548c-.436 0-.823.22-1.05.55a6.898 6.898 0 00-.759-.534c-.416-.24-.887-.384-1.531-.45C11.558-.06 9.445.037 4.564.017a.521.521 0 00-.316.114C3.023.796-.007 5.904 0 10.663c.025 1.138.541 1.78 1.532 1.78H8.04c-.39 2.47-.131 4.738.735 6.208.467.794 1.148 1.238 1.823 1.331a2.034 2.034 0 00.663-.022m-2.155-7.913c.056-.28-.202-.579-.497-.579H1.674c-.356-.035-.67-.091-.67-.913 0-1.047.166-2.886 1.031-5.057C2.9 3.326 4.19.967 4.638 1.044c1.214-.081 7.014-.109 8.108 0 .556.055.818.134 1.113.305.265.152.59.423 1.066.791v7.6c-2.349 2.88-2.979 5.302-3.096 8.3-.338 1.495-1.702 1.083-2.179.13-.697-2.402-.88-4.442-.545-6.123m11.631-1.309h-4.548c-.149 0-.252-.1-.252-.244V1.722c0-.144.103-.244.252-.244h4.548c.15 0 .253.1.253.244v8.772c0 .144-.103.244-.253.244"></path></svg>
